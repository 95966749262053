import { lazy, Suspense, LazyExoticComponent } from 'react';
import { Route, Redirect, Router, Switch } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE, ADMIN_ROUTES, SUPER_ADMIN_PUBLIC_ROUTE } from './route.constants';
import Loader from './components/utility/loader';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store/rootReducer';
import history from './library/helpers/history';

import { SpinCustom } from './components/uielements/spin';
import { getSubDomain } from './library/services/subdomain';
import SignUpAdminStep2 from './containers/SignUpAdminStep2/SignUpAdminStep2';
import SubscriptionPlan from './containers/SubscriptionPlan/SubscriptionPlan';
import CreditCardInfo from './containers/CreditCardInfo/CreditCardInfo';
import BillingInfo from './containers/BillingInfo/BillingInfo';
import AdminDashboard from './containers/Admin/AdminDashboard/AdminDashboard';
import VerifyCard from './containers/CreditCardInfo/VerifyCard';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));


const TOKEN_KEY = 'id_token';

export const getStoredJWTToken = () => {
  const sessionToken = sessionStorage.getItem(TOKEN_KEY);
  const localToken = localStorage.getItem(TOKEN_KEY);

  if (!sessionToken && localToken) {
    sessionStorage.setItem(TOKEN_KEY, localToken);
    return localToken;
  }

  return sessionToken || localToken || null;
};

type PublicRoutesType = {
  path: string;
  exact?: boolean;
  component: LazyExoticComponent<any>;
}[];

const allPrivateRoutes = [
  {
    path: ADMIN_ROUTES.SIGN_UP_ADMIN_2,
    component: <SignUpAdminStep2 />
  },
  {
    path: PUBLIC_ROUTE.SUBSCRIPTION_PLAN,
    component: <SubscriptionPlan />
  },
  {
    path: PUBLIC_ROUTE.CREDIT_CARD_INFO,
    component: <CreditCardInfo />
  },
  {
    path: PUBLIC_ROUTE.BILLING,
    component: <BillingInfo />
  },
];

const adminSignupRoutes: PublicRoutesType = [
  {
    path: ADMIN_ROUTES.WORKSPACE_SEARCH,
    exact: true,
    component: lazy(() => import('./containers/WorkSpaces/WorkSpaces')),
  },
  {
    path: ADMIN_ROUTES.WORKSPACE_RESULT,
    exact: true,
    component: lazy(() => import('./containers/WorkSpaces/WorkSpaceResult')),
  },
  {
    path: ADMIN_ROUTES.SIGN_UP_ADMIN,
    exact: true,
    component: lazy(() => import('./containers/SignupAdmin/SignupAdmin')),
  },
  {
    path: ADMIN_ROUTES.SIGN_UP_ADMIN_1,
    exact: true,
    component: lazy(
      () => import('./containers/SignUpAdminStep1/SignUpAdminStep1')
    ),
  },
  {
    path: ADMIN_ROUTES.SIGN_UP_ADMIN_2_MANUAL,
    exact: true,
    component: lazy(
      () => import('./containers/SignUpAdminStep2Manual/SignUpAdminStep2Manual')
    ),
  },
  {
    path: PUBLIC_ROUTE.SETTING,
    exact: true,
    component: lazy(
      () => import('./containers/SettingTimeZone/SettingTimeZone')
    ),
  },
  {
    path: PUBLIC_ROUTE.PORTAL_SUCCESS,
    exact: true,
    component: lazy(() => import('./containers/PortalSuccess/PortalSuccess')),
  },
  {
    path: PUBLIC_ROUTE.SUBSCRIPTION_PLAN,
    exact: true,
    component: lazy(
      () => import('./containers/SubscriptionPlan/SubscriptionPlan')
    ),
  },
  {
    path: PUBLIC_ROUTE.SUBSCRIPTION_PLAN_DETAILS,
    exact: true,
    component: lazy(
      () =>
        import('./containers/SubscriptionPlanDetails/SubscriptionPlanDetails')
    ),
  },
  {
    path: PUBLIC_ROUTE.PAYMENT,
    exact: true,
    component: lazy(() => import('./containers/PaymentDetails/PaymentDetails')),
  },
  {
    path: PUBLIC_ROUTE.BILLING,
    exact: true,
    component: lazy(() => import('./containers/BillingInfo/BillingInfo')),
  },
  {
    path: PUBLIC_ROUTE.CREDIT_CARD_INFO,
    exact: true,
    component: lazy(() => import('./containers/CreditCardInfo/CreditCardInfo')),
  },
  {
    path: PUBLIC_ROUTE.VERIFY_CARD,
    exact: true,
    component: lazy(() => import('./containers/CreditCardInfo/VerifyCard')),
  },
  {
    path: '/verify/:method',
    exact: false,
    component: lazy(() => import('./containers/AuthLogin/AuthLogin')),
  },
];

const superAdminPublicRoutes = [
  {
    path: '/superadmin' || SUPER_ADMIN_PUBLIC_ROUTE.ADMIN_SIGN_IN,
    exact: true,
    component: lazy(() => import('./containers/Admin/AdminLogin/AdminLogin'))
  },
  {
    path: SUPER_ADMIN_PUBLIC_ROUTE.ADMIN_SIGN_IN,
    exact: true,
    component: lazy(() => import('./containers/Admin/AdminLogin/AdminLogin'))
  },
  {
    path: SUPER_ADMIN_PUBLIC_ROUTE.FORGET_PASSWORD,
    exact: true,
    component: lazy(() => import('./containers/Admin/AdminForgetPassword/AdminForgetPassword'))
  },
  {
    path: SUPER_ADMIN_PUBLIC_ROUTE.RESET_PASSWORD,
    exact: true,
    component: lazy(() => import('./containers/Admin/AdminResetPassword/AdminResetPassword'))
  },
  {
    path: '/superadmin/dashboard',
    exact: true,
    component: lazy(() => import('./containers/Admin/AdminDashboard/AdminDashboard'))
  }
];

const publicRoutes: PublicRoutesType = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('./containers/SignupAdmin/SignupAdmin')),
  },
  {
    path: PUBLIC_ROUTE.SIGNIN,
    exact: true,
    component: lazy(() => import('./containers/LogIn/LogIn')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    exact: true,
    component: lazy(() => import('./containers/ForgetPassword/ForgetPassword')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    exact: true,
    component: lazy(() => import('./containers/ResetPassword/ResetPassword')),
  },
  {
    path: PUBLIC_ROUTE.SET_PASSWORD,
    exact: true,
    component: lazy(
      () => import('./containers/ManuallyAddedUserPswd/SetPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP_EMPLOYEE_1,
    exact: true,
    component: lazy(
      () => import('./containers/SignUpEmployeeStep1/SignUpEmployeeStep1')
    ),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP_EMPLOYEE_2_DEPT,
    exact: true,
    component: lazy(
      () => import('./containers/SignUpDeptStep2/SignUpDeptStep2')
    ),
  },
  {
    path: PUBLIC_ROUTE.OBJECTIVE,
    exact: true,
    component: lazy(() => import('./containers/ViewVMSBOKR/ViewVMSBOKR')),
  },
  {
    path: PUBLIC_ROUTE.KEYRESULT,
    exact: true,
    component: lazy(() => import('./containers/ViewVMSBOKR/ViewVMSBOKR')),
  },
  {
    path: PUBLIC_ROUTE.TASK,
    exact: true,
    component: lazy(() => import('./containers/ViewVMSBOKR/ViewVMSBOKR')),
  },
  {
    path: PUBLIC_ROUTE.VISION,
    exact: true,
    component: lazy(() => import('./containers/ViewVMSBOKR/ViewVMSBOKR')),
  },
  {
    path: PUBLIC_ROUTE.MISSION,
    exact: true,
    component: lazy(() => import('./containers/ViewVMSBOKR/ViewVMSBOKR')),
  },
  {
    path: PUBLIC_ROUTE.BENEFIT,
    exact: true,
    component: lazy(() => import('./containers/ViewVMSBOKR/ViewVMSBOKR')),
  },
  {
    path: PUBLIC_ROUTE.STRATEGY,
    exact: true,
    component: lazy(() => import('./containers/ViewVMSBOKR/ViewVMSBOKR')),
  },
  {
    path: PUBLIC_ROUTE.VERIFY_CARD,
    exact: true,
    component: lazy(() => import('./containers/CreditCardInfo/VerifyCard')),
  },
  {
    path: '/verify/:method',
    exact: false,
    component: lazy(
      () => import('./containers/AuthLoginRedirect/AuthLoginRedirect')
    ),
  },
];
function PrivateRoute({ children, redirect, ...rest }) {
  const hasToken = getStoredJWTToken();
  const isLoggedIn = useSelector((state: RootState) => state.Auth.idToken) || hasToken;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function AdminPrivateRoute({ children, redirect, ...rest }) {
  const isLoggedIn = localStorage.getItem('admin_accessToken');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  const subdomain = getSubDomain();

  const globalLoader = useSelector((state: RootState) => state.App.globalLoader);

  return (
    <SpinCustom spinning={globalLoader > 0}>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Router history={history}>
            {subdomain === 'executive' ? (
              !window.location.pathname.includes('superadmin') ? (
                <Switch>
                  {allPrivateRoutes.map((route, index) => (
                    <PrivateRoute path={route.path} redirect={ADMIN_ROUTES.SIGN_UP_ADMIN_1}>
                      {route.component}
                    </PrivateRoute>
                  ))}
                  {adminSignupRoutes.map((route, index) => (
                    <Route key={index} path={route.path} exact={route.exact} component={route.component} />
                  ))}
                  <Route path='*' exact={true} component={lazy(() => import('./containers/Page404/Page404'))} />
                </Switch>
              ) : (
                //!SECTION  SUPER ADMIN ROUTES
                <Switch>
                  {superAdminPublicRoutes.map((route, index) => (
                    <Route key={index} path={route.path} exact={route.exact} component={route.component} />
                  ))}
                  <AdminPrivateRoute path='/superadmin/dashboard' redirect='/superadmin/login'>
                    <AdminDashboard />
                  </AdminPrivateRoute>
                  <Route path='*' exact={true} component={lazy(() => import('./containers/Page404/Page404'))} />
                </Switch>
              )
            ) : (
              /* Organization Routes */
              <Switch>
                {publicRoutes.map((route, index) => (
                  <Route key={index} path={route.path} exact={route.exact} component={route.component} />
                ))}
                <PrivateRoute path='/dashboard' redirect='/'>
                  <Dashboard />
                </PrivateRoute>
                <Route path='*' exact={true} component={lazy(() => import('./containers/Page404/Page404'))} />
              </Switch>
            )}
          </Router>
        </Suspense>
      </ErrorBoundary>
    </SpinCustom>
  );
}
